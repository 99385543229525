<template>
  <footer class="footer footer-theme position-relative">
    <b-container style="max-width: 1340px;">
      <div class="footer-top">
        <b-row>
          <b-col lg="2" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget logo-widget">
              <div class="text">
                <h4>JobMATCH GmbH</h4>

                <p>
                  M7 14<br>
                  68161 Mannheim<br>
                  info@jobmatch.gmbh<br>
                </p>
              </div>
            </div>
          </b-col>
          <b-col lg="2" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links1" :key="`links1-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="2" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links2" :key="`links2-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="2" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links3" :key="`links3-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="2" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links4" :key="`links4-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="2" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links5" :key="`links5-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="footer-bottom">
        <b-row>
          <b-col>
            <div class="clearfix">
              <div class="copyright pull-left">
                <p>
                  © {{ new Date().getFullYear() }} JobMATCH GmbH
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

interface LinkItem {
  text: string
  link?: string
  hasIcon?: boolean
}

@Component
export default class FooterComponent extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return 'de'; // AppModule.language;
  }

  get links1() {
    const resultArr: LinkItem[] = [
      {
        text: 'Willkommen',
        link: `https://jobmatch.gmbh/${this.lang}/index.php`,
      },
    ];
    return resultArr;
  }

  get links2() {
    const resultArr: LinkItem[] = [
      {
        text: 'Plattformen',
        link: `https://jobmatch.gmbh/${this.lang}/Plattformen/index.php`,
      },
      {
        text: 'Bedarfe und Herausforderungen',
        link: `https://jobmatch.gmbh/${this.lang}/Plattformen/index.php`,
      },
      {
        text: 'Lösungskomponenten',
        link: `https://jobmatch.gmbh/${this.lang}/Plattformen/loesungsansatz.php`,
      },
    ];
    return resultArr;
  }

  get links3() {
    const resultArr: LinkItem[] = [
      {
        text: 'Projektunterstützung',
        link: `https://jobmatch.gmbh/${this.lang}/Projektsupport/index.php`,
      },
      {
        text: 'Deutschtraining',
        link: `https://jobmatch.gmbh/${this.lang}/Projektsupport/deutschtraining.php`,
      },
      {
        text: 'Angebote zur Projektunterstützung',
        link: `https://jobmatch.gmbh/${this.lang}/Projektsupport/index.php`,
      },
      {
        text: 'Integrationscoaching',
        link: `https://jobmatch.gmbh/${this.lang}/Projektsupport/integrationscoaching.php`,
      },
    ];
    return resultArr;
  }

  get links4() {
    const resultArr: LinkItem[] = [
      {
        text: 'Über uns',
        link: `https://jobmatch.gmbh/${this.lang}/Ueber-uns/index.php`,
      },
    ];
    return resultArr;
  }

  get links5() {
    const resultArr: LinkItem[] = [
      {
        text: 'So erreichen Sie uns',
        link: `https://jobmatch.gmbh/${this.lang}/Kontakt/index.php`,
      },
      {
        text: 'Weitere Standorte',
        link: `https://jobmatch.gmbh/${this.lang}/Kontakt/index.php`,
      },
      {
        text: 'Kontaktformular',
        link: `https://jobmatch.gmbh/${this.lang}/${this.lang}/Kontakt/form.php`,
      },
      {
        text: 'Datenschutz',
        link: `https://jobmatch.gmbh/${this.lang}/Kontakt/Datenschutz.php`,
      },
    ];
    return resultArr;
  }
}
</script>
<style lang="scss" scoped>
[theme="talentpool"] {
  .footer-theme {
    background: #69726A;
    padding: 2.35rem 0 0;
    .pull-left {
      float: left;
    }
    .pull-right {
      float: right;
    }
    a {
      text-decoration: none;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
    ul {
      padding: 0;
    }
    h4 {
      color: #ffffff;
      font-size: 20px;
    }
    .footer-top {
      position: relative;
      display: block;
      padding: 0px 0px 50px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      p {
        color: #ffffff;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 26px;
      }
      .widget-content li {
        a {
          position: relative;
          display: inline-block;
          font-size: 18px;
          line-height: 26px;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: var(--aba-primary);
          }
        }
      }
      .footer-column:nth-child(2) .links-widget {
        margin-left: 60px;
      }
    }
    .footer-bottom {
      position: relative;
      padding-top: 15px;
      .copyright p {
        color: #ffffff;
        line-height: 26px;
        font-size: 16px;
        a {
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          &:hover {
            color:  var(--aba-primary);
          }
        }
      }
      .footer-nav li {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          &:before {
            display: none;
          }
        }
        a {
          position: relative;
          display: inline-block;
          font-size: 16px;
          line-height: 26px;
          font-weight: 300;
          color: #ffffff;
          &:hover {
            color: var(--aba-primary);
          }
        }
        &:before {
          position: absolute;
          content: '.';
          color: #ffffff;
          width: 2px;
          height: 16px;
          top: -3px;
          right: -12px;
        }
      }
    }
  }
}
</style>
