const sids = [
  'jig',
  'talentpool',
  'edunet',
  'wconat',
  'ncph',
  't4a',
  'edunetke',
  'kh',
  'rhh',
];

export const group = (sid: string) => sids.includes(sid);
