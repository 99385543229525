export enum ThemeSids {
  aba = 'aba',
  abatest = 'abatest',
  jig = 'jig',
  talentpool = 'talentpool',
  edunet = 'edunet',
  wconat = 'wconat',
  ncph = 'ncph',
  t4a = 't4a',
  edunetke = 'edunetke',
  kh = 'kh',
  rhh = 'rhh',
}

export interface ThemeSite {
  domain?: string
  sid: ThemeSids
  site?: string
  color: string
  title?: any
  language?: string
  languageDropdown: boolean
}
